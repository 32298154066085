import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";

const ItemDialog = ({ item, shareUrl, open, onClose, branchLogo }) => {
  const { offerStyle, logoStyle } = useStyles();
  const { item_name, item_image, offer_message1, offer_message2 } = item || {};
  return (
    <Dialog onClose={onClose} aria-labelledby="item-info-dialog" open={open}>
      <DialogTitle>{item_name}</DialogTitle>
      <DialogContent>
        <Box textAlign="center" position="relative">
          <img src={branchLogo} alt="branch logo" className={logoStyle} />
          <img
            src={item_image}
            alt={item_name}
            title={item_name}
            width="250"
            height="auto"
          />
        </Box>
        <Typography className={offerStyle}>
          <span>{offer_message1}</span>
          {offer_message2}
        </Typography>
        <Link href={shareUrl} target="_blank" rel="noreferrer" underline="none">
          <Button
            fullWidth
            variant="contained"
            disableElevation
            color="primary"
            endIcon={<GetApp />}
          >
            Order Now - Download ZEEV
          </Button>
        </Link>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          disableElevation
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  offerStyle: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),

    "& span": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      marginRight: theme.spacing(1),
      borderRadius: 1000,
    },
  },
  titleStyle: {
    maxWidth: "90%",
  },
  logoStyle: {
    position: "absolute",
    width: theme.spacing(10),
    bottom: 0,
    right: 0,
    borderRadius: 8,
    zIndex: 1,
  },
  dialogPaperStyle: {
    overflow: "initial",
  },
}));

ItemDialog.propTypes = {
  item: PropTypes.object,
  shareUrl: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  branchLogo: PropTypes.string,
};

export default ItemDialog;
