import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const shareButtons = [
  [EmailShareButton, EmailIcon],
  [FacebookShareButton, FacebookIcon, true],
  [InstapaperShareButton, InstapaperIcon],
  [LinkedinShareButton, LinkedinIcon],
  [PinterestShareButton, PinterestIcon],
  [RedditShareButton, RedditIcon],
  [TelegramShareButton, TelegramIcon],
  [TumblrShareButton, TumblrIcon],
  [TwitterShareButton, TwitterIcon],
  [WhatsappShareButton, WhatsappIcon],
];

const ShareDialog = ({ open, url, onClose, title, hashtags, imageUrl }) => {
  return (
    <Dialog onClose={onClose} aria-labelledby="share-dialog" open={open}>
      <DialogTitle>Share offer with friends...</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Share link:{" "}
          <strong>
            <code>{url}</code>
          </strong>
        </DialogContentText>
        {shareButtons.map(([ShareButton, ShareIcon], index) => {
          return (
            <Box display="inline-block" margin={0.5} key={`${index}_${title}`}>
              <ShareButton
                url={url}
                title={title}
                quote={title}
                subject={title}
                description={title}
                hashtags={hashtags}
                hashtag={`#${hashtags[0]}`}
                tags={hashtags}
                image={imageUrl}
                media={imageUrl}
                source="https://zeev.in"
              >
                <ShareIcon size={32} round />
              </ShareButton>
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          disableElevation
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ShareDialog.propTypes = {
  open: PropTypes.bool,
  url: PropTypes.string,
  title: PropTypes.string,
  hashtags: PropTypes.arrayOf(PropTypes.string),
  imageUrl: PropTypes.string,
  onClose: PropTypes.func,
};

export default ShareDialog;
