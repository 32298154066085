import React from "react";
import PropTypes from "prop-types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import CarouselItem from "./CarouselItem";

const OfferDetailCarousel = ({ images, children }) => {
  return (
    <Carousel
      showArrows={false}
      showThumbs={false}
      autoPlay
      showStatus={false}
      infiniteLoop
      emulateTouch
    >
      {children
        ? children
        : images.map((imgSrc) => <CarouselItem src={imgSrc} key={imgSrc} />)}
    </Carousel>
  );
};

OfferDetailCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
};

export default OfferDetailCarousel;
