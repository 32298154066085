import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import * as ServiceWorkerRegistration from "./serviceWorkerRegistration";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00ACC2",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FF776F",
      contrastText: "#ffffff",
    },
  },
});

ReactDOM.render(
  /**
   * @todo
   * Set the `basename` accordingly.
   */
  <Router basename="/">
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Router>,
  document.getElementById("zeev-offers")
);

reportWebVitals();
ServiceWorkerRegistration.register();
