import { Route, Switch } from "react-router";
import Page404 from "./lib/common/Page404";
import OfferDetail from "./lib/OfferDetail/OfferDetail";
import OffersList from "./lib/OffersList/OffersList";

function App() {
  return (
    <Switch>
      <Route path="/" exact render={() => <OffersList />} />
      <Route path="/branch/:branch_id?" render={() => <OffersList />} />
      <Route path="/detail/:offer_id" exact render={() => <OfferDetail />} />
      <Route path="*" render={() => <Page404 />} />
    </Switch>
  );
}

export default App;
