import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { ReactComponent as FilterIcon } from "../../assets/filter.svg";

const FilterItems = ({
  onSearchChange,
  searchValue,
  filters,
  onFilterClick,
}) => {
  const [filterEl, setFilterEl] = React.useState(null);

  const handleOpenFilter = (event) => {
    setFilterEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterEl(null);
  };

  const handleFilterClick = (filter) => () => {
    onFilterClick(filter);
    handleCloseFilter();
  };

  const onSearchClear = () => {
    onSearchChange({
      target: {
        value: "",
      },
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      py={1.5}
      px={3}
      bgcolor="white"
      position="sticky"
      top={0}
      zIndex={2}
      borderBottom="1px solid #e0e0e0"
    >
      <Box flexGrow={1} marginRight={1} maxWidth={400}>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          onChange={onSearchChange}
          value={searchValue}
          placeholder="Search for an item"
          InputProps={{
            endAdornment:
              searchValue !== "" ? (
                <InputAdornment position="end">
                  <IconButton onClick={onSearchClear}>
                    <Clear fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
        />
      </Box>
      <Tooltip title="Filter items" arrow placement="top">
        <Button variant="outlined" size="small" onClick={handleOpenFilter}>
          <FilterIcon style={{ width: 18 }} />
        </Button>
      </Tooltip>
      <Menu
        id="filter-menu"
        anchorEl={filterEl}
        keepMounted
        open={Boolean(filterEl)}
        onClose={handleCloseFilter}
      >
        {filters.map((filter) => (
          <MenuItem key={filter} onClick={handleFilterClick(filter)}>
            {filter}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

FilterItems.propTypes = {
  onSearchChange: PropTypes.func,
  searchValue: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string),
  onFilterClick: PropTypes.func,
};

export default FilterItems;
