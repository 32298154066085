import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, useMediaQuery } from "@material-ui/core";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import OfferDetailCarousel from "./OfferDetailCarousel";
import CarouselItem from "./CarouselItem";

const ExtraOffers = ({ extraOffers }) => {
  const isMobile = useMediaQuery("(max-width:700px)");
  const { itemStyle } = useStyles({
    isMobile,
  });

  if (isEmpty(extraOffers)) {
    return null;
  }

  return (
    <OfferDetailCarousel>
      {extraOffers.map((offer, index) => {
        const { offer_id, offer_title, offer_image } = offer;
        const isEmptyOfferId = isEmpty(offer_id);
        const Wrapper = isEmptyOfferId ? Box : Link;
        const to = isEmptyOfferId ? null : `/detail/${offer_id}`;
        return (
          <Wrapper
            className={itemStyle}
            to={to}
            key={offer_id + offer_title + index}
          >
            <CarouselItem src={offer_image} />
          </Wrapper>
        );
      })}
    </OfferDetailCarousel>
  );
};

const useStyles = makeStyles(() => ({
  itemStyle: {
    display: "block",
    width: "100%",
    borderRadius: 8,
    overflow: "hidden",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
  },
}));

ExtraOffers.propTypes = {
  extraOffers: PropTypes.arrayOf(PropTypes.object),
};

export default ExtraOffers;
