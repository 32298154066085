import React, { memo } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const defaultTitle = "Offers";
const defaultDescription = "Check out the Zeev offers!";
const defaultImage = process.env.PUBLIC_URL + "/banner.jpg";
const defaultShareUrl = "Zeev.in";
const defaultFavicon = process.env.PUBLIC_URL + "/favicon.ico";

const SEO = ({ metadata }) => {
  const { pathname } = useLocation();
  const { title, description, image, share_url, favicon } = metadata || {};

  const TITLE = `${title ?? defaultTitle} | Zeev`;

  const DESCRIPTION = `${
    description ?? defaultDescription
  }. Download the Zeev app now | ${share_url ?? defaultShareUrl}`;

  const FAVICON = favicon ?? defaultFavicon;
  const IMAGE = image ?? defaultImage;

  const URL = `https://zeev-offer.netlify.app${pathname}`;

  return (
    <Helmet>
      <link rel="icon" href={FAVICON} />
      <title>{TITLE}</title>
      <meta name="description" content={DESCRIPTION} />
      <meta property="og:title" content={TITLE} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={DESCRIPTION} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:description" content={DESCRIPTION} />
      <meta name="twitter:image" content={IMAGE} />

      <meta property="og:image" content={`${IMAGE}`} />
      <meta property="og:image:alt" content={DESCRIPTION} />

      <link rel="canonical" href={URL} />
      <meta property="og:url" content={URL} />
      <meta name="twitter:url" content={URL} />
    </Helmet>
  );
};

SEO.propTypes = {
  metadata: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    share_url: PropTypes.string,
    favicon: PropTypes.string,
  }),
};

export default memo(SEO);
