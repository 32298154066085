import _Axios from "axios";
import OffersList from "./mock_data/offers_list.json";
import OfferDetail from "./mock_data/offer_detail.json";

const Axios = _Axios.create({
  baseURL: "https://app.zeev.live/API/web",
});

export const fetchAllOffers = async () => {
  if (Axios.defaults.baseURL.startsWith("http://")) {
    return Promise.resolve({ data: OffersList });
  }
  return Axios.get("/offer_list");
};

export const fetchOfferDetails = (offer_id) => {
  if (Axios.defaults.baseURL.startsWith("http://")) {
    return Promise.resolve({ data: OfferDetail });
  }
  return Axios.post("/offer_detail", { offer_id });
};
