import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";

const DealItemInfo = ({ offer1, offer2 }) => {
  const {
    wrapperStyle,
    offer1Style,
    offer2Style,
    offer1TextStyle,
    offer2TextStyle,
  } = useStyles();
  return (
    <div className={wrapperStyle}>
      <div className={offer1Style}>
        <Typography
          className={offer1TextStyle}
          variant="caption"
          align="center"
        >
          {offer1}
        </Typography>
      </div>
      <div className={offer2Style}>
        <Typography className={offer2TextStyle}>{offer2}</Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperStyle: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  offer1Style: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    width: 60,
    height: 60,
    borderRadius: 1000,
    position: "absolute",
    bottom: -10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  offer1TextStyle: {
    fontWeight: "bold",
    lineHeight: 1,
  },
  offer2Style: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(1.5) + 70,
    marginTop: theme.spacing(0.5),
  },
  offer2TextStyle: {
    color: theme.palette.secondary.contrastText,
    fontSize: 12,
  },
}));

DealItemInfo.propTypes = {
  offer1: PropTypes.string,
  offer2: PropTypes.string,
};

export default DealItemInfo;
