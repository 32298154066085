import { Box, Typography } from "@material-ui/core";
import React from "react";

const Page404 = () => {
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h1" align="center">
        404
      </Typography>
      <Typography>The page your requested for does not exist</Typography>
    </Box>
  );
};

export default Page404;
