import React, { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Chip,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import DealItem from "./DealItem";
import ItemDialog from "./ItemDialog";
import FilterItems from "./FilterItems";
import { uniq } from "lodash";
import { Clear } from "@material-ui/icons";
import DownloadButton from "./DownloadButton";

const OfferDealItems = ({ items, share_url, detail: { branch_logo } }) => {
  const { itemStyle } = useStyles();
  const [itemSelected, setItemSelected] = useState(null);
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState([]);
  const isMobile = useMediaQuery("(max-width:700px)");

  const filterNames = useMemo(() => {
    const offerFilters = items.map((x) => x.discount_filter);
    const names = items.map((x) => x.master_category);
    return uniq([...offerFilters, ...names]);
  }, [items]);

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const showDialog = (item) => () => {
    setItemSelected(item);
    setOpenItemDialog(true);
  };

  const closeDialog = () => {
    setOpenItemDialog(false);
  };

  const getFilteredItems = () => {
    let filteredItems = [...items];
    if (filters.length > 0) {
      filteredItems = filteredItems.filter(
        (x) =>
          filters.includes(x.master_category) ||
          filters.includes(x.discount_filter)
      );
    }
    if (searchValue === "") {
      return filteredItems;
    }
    return filteredItems.filter((x) =>
      x.item_name.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const onFilterAdd = (filter) => {
    setFilters((prevState) => [...prevState, filter]);
  };

  const onFilterRemove = (filter) => () => {
    setFilters((prevState) => prevState.filter((x) => x !== filter));
  };

  const filteredItems = getFilteredItems();

  return (
    <>
      <ItemDialog
        open={openItemDialog}
        item={itemSelected}
        shareUrl={share_url}
        onClose={closeDialog}
        branchLogo={branch_logo}
      />
      <Box marginTop={4}>
        <Box paddingX={3}>
          <Typography variant="h6">
            <strong>Deals</strong>
          </Typography>
          <Divider />
        </Box>
        <FilterItems
          searchValue={searchValue}
          onSearchChange={onSearchChange}
          filters={filterNames}
          onFilterClick={onFilterAdd}
        />
        <Box paddingX={3}>
          {filters.length > 0 &&
            filters.map((f) => (
              <Box marginTop={1} marginRight={1} display="inline-block">
                <Chip
                  key={f}
                  label={f}
                  onDelete={onFilterRemove(f)}
                  deleteIcon={<Clear />}
                  color="primary"
                />
              </Box>
            ))}

          <Box marginTop={4}>
            <Grid container spacing={3}>
              {filteredItems.length > 0 &&
                filteredItems.map((i, index) => {
                  const itemToRender = (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      lg={2}
                      onClick={showDialog(i)}
                      className={itemStyle}
                    >
                      <DealItem item={i} branchLogo={branch_logo} />
                    </Grid>
                  );
                  let downloadZeevBtn = isMobile ? (
                    <Grid item xs={12} className={itemStyle}>
                      <DownloadButton
                        shareUrl={share_url}
                        size="large"
                        color="secondary"
                      />
                    </Grid>
                  ) : null;

                  return (
                    <Fragment key={i.item_code + index}>
                      {itemToRender}
                      {(index + 1) % 8 === 0 ? downloadZeevBtn : null}
                    </Fragment>
                  );
                })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  itemStyle: {
    cursor: "pointer",
  },
}));

OfferDealItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  share_url: PropTypes.string,
  detail: PropTypes.object,
};

export default OfferDealItems;
