import { Box, Button, Typography } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchOfferDetails } from "../API";
import PageLoading from "../common/PageLoading";
import DownloadButton from "./components/DownloadButton";
import ExtraOffers from "./components/ExtraOffers";
import FabDownload from "./components/FabDownload";
import HeroImage from "./components/HeroImage";
import OfferDealItems from "./components/OfferDealItems";
import SEO from "./components/SEO";
import ShareDialog from "./components/ShareDialog";

const OfferDetail = () => {
  const { offer_id } = useParams();
  const [offerDetail, setOfferDetail] = useState(null);
  const [offerNotFound, setOfferNotFound] = useState(false);

  const [showDownloadBtn, setShowDownloadBtn] = useState(false);

  const [openShare, setOpenShare] = useState(false);

  useEffect(() => {
    const load = async () => {
      try {
        const response = await fetchOfferDetails(offer_id);
        setOfferDetail(response.data);
      } catch (e) {
        const { code } = e?.response?.data || {};
        if (code === "105") {
          setOfferNotFound(true);
        }
      }
    };
    load();
  }, [offer_id]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (e) => {
    setShowDownloadBtn(e.target.scrollingElement.scrollTop > 250);
  };

  if (offerNotFound) {
    return <Typography>Offer not found/Invalid Offer ID</Typography>;
  }

  if (offerDetail === null) {
    return <PageLoading />;
  }

  const {
    offer_title,
    // offer_images,
    offer_images_wide,
    offer_detail,
    offer_validity,
    item_list,
    share_text,
    share_image,
    share_url,
    metadata,
    extra_offers,
  } = offerDetail;

  return (
    <div>
      <SEO metadata={metadata} />
      <ShareDialog
        url={share_url}
        title={share_text}
        hashtags={generateHashtags(offer_title, offer_detail, offer_validity)}
        imageUrl={share_image}
        open={openShare}
        onClose={() => setOpenShare(false)}
      />
      <HeroImage src={offer_images_wide[0]} alt={offer_detail} />
      <Box>
        <Box padding={3}>
          <Typography variant="h6">
            <strong>{offer_title}</strong>
          </Typography>
          <Box marginBottom={2}>
            <Typography variant="caption">{offer_detail}</Typography>
          </Box>
          <Box marginBottom={2}>
            <Typography variant="subtitle2">{offer_validity}</Typography>
          </Box>
          <Box width="100%" maxWidth={400}>
            <Box marginBottom={1}>
              <DownloadButton shareUrl={share_url} />
            </Box>
            <Button
              fullWidth
              variant="outlined"
              disableElevation
              color="primary"
              endIcon={<Share />}
              onClick={() => setOpenShare(true)}
            >
              Share with your Friends
            </Button>
          </Box>
          <Box width="100%" marginTop={2}>
            <ExtraOffers extraOffers={extra_offers} />
          </Box>
        </Box>
        <OfferDealItems
          items={item_list}
          share_url={share_url}
          detail={offerDetail}
        />
        <FabDownload shareUrl={share_url} show={showDownloadBtn} />
      </Box>
    </div>
  );
};

export default OfferDetail;

const generateHashtags = (title, detail, validity) => {
  const specialChars = new RegExp(/[.,!\-+()&]/gi);
  let hashtags = ["Zeev", "Offers"];

  [title, detail, validity].forEach((text) => {
    if (!isEmpty(text)) {
      let textHash = "";
      text
        .trim()
        .split(/\s+/g)
        .forEach((word) => {
          // Ignore special characters
          if (!specialChars.test(word)) {
            textHash += word[0].toUpperCase() + word.substr(1);
          }
        });
      hashtags.push(textHash);
    }
  });

  return hashtags;
};
