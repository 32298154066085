import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const CarouselItem = ({ src }) => {
  const { wrapperStyle } = useStyles({ src });

  return <div className={wrapperStyle} />;
};

const useStyles = makeStyles(() => ({
  wrapperStyle: {
    width: "100%",
    height: 200,
    backgroundImage: (props) => `url(${props.src})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",
    backgroundPosition: "center",
  },
  "@media (min-width: 800px)": {
    wrapperStyle: {
      height: 300,
      margin: "0 auto",
    },
  },
}));

CarouselItem.propTypes = {
  src: PropTypes.string,
};

export default memo(CarouselItem);
