import { Button, Link } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import React from "react";
import PropTypes from "prop-types";

const DownloadButton = ({ shareUrl, ...rest }) => {
  return (
    <Link href={shareUrl} target="_blank" rel="noreferrer" underline="none">
      <Button
        fullWidth
        variant="contained"
        disableElevation
        color="primary"
        endIcon={<GetApp />}
        {...rest}
      >
        Order Now - Download ZEEV
      </Button>
    </Link>
  );
};

DownloadButton.propTypes = {
  shareUrl: PropTypes.string,
};

export default DownloadButton;
