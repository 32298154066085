import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const OfferBanner = ({
  offer: { offer_id, offer_image, offer_title, offer_detail },
}) => {
  const { wrapperStyle, imageStyle } = useStyles();
  return (
    <Link to={`/detail/${offer_id}`}>
      <div className={wrapperStyle}>
        <img
          className={imageStyle}
          src={offer_image}
          alt={offer_detail}
          title={offer_title}
        />
      </div>
    </Link>
  );
};

const useStyles = makeStyles(() => ({
  wrapperStyle: {
    width: "100%",
    height: 200,
    borderRadius: 8,
    overflow: "hidden",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
  },
  imageStyle: {
    width: "100%",
    height: "100%",
  },
}));

OfferBanner.propTypes = {
  offer: PropTypes.object,
};

export default OfferBanner;
