import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, useMediaQuery } from "@material-ui/core";

const HeroImage = ({ src, alt }) => {
  const isMobile = useMediaQuery("(max-width:700px)");
  const { wrapper, backdropStyle } = useStyles({ isMobile });

  return (
    <div className={wrapper}>
      <Box zIndex={1} height="100%">
        <img
          src={src}
          alt={alt}
          width={isMobile ? "100%" : "auto"}
          height={isMobile ? "auto" : "100%"}
        />
      </Box>
      <img src={src} alt={alt} className={backdropStyle} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    overflow: "hidden",
    width: "100%",
    height: (props) => (props.isMobile ? 250 : 325),
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    transition: "height 200ms ease",
    position: "relative",
  },
  backdropStyle: {
    width: "115%",
    height: "115%",
    transform: "translate(-30px, -30px)",
    position: "absolute",
    filter: "blur(40px)",
  },
}));

HeroImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default memo(HeroImage);
