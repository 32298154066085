import { Box, Fab, Link, Zoom } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import React from "react";
import PropTypes from "prop-types";

const FabDownload = ({ show, shareUrl }) => {
  return (
    <Zoom key={shareUrl} in={show} unmountOnExit>
      <Box position="fixed" bottom={20} right={20}>
        <Link
          href={shareUrl}
          target="_blank"
          rel="noreferrer"
          underline="none"
          color="primary"
        >
          <Fab variant="extended" color="primary">
            <Box marginRight={1} alignItems="center" display="flex">
              <GetApp />
            </Box>
            Download Zeev
          </Fab>
        </Link>
      </Box>
    </Zoom>
  );
};

FabDownload.propTypes = {
  show: PropTypes.bool,
  shareUrl: PropTypes.string,
};

export default FabDownload;
