import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchAllOffers } from "../API";
import Page404 from "../common/Page404";
import PageLoading from "../common/PageLoading";
import SEO from "../OfferDetail/components/SEO";
import OfferBanner from "./components/OfferBanner";

const OffersList = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [branchName, setBranchName] = useState(null);
  const [branchIcon, setBranchIcon] = useState(null);

  const { branch_id } = useParams();

  useEffect(() => {
    setLoading(true);
    const load = async () => {
      const {
        data: { offer_list },
      } = await fetchAllOffers();
      const filteredOffers = branch_id
        ? offer_list.filter((x) => x.branch_id === branch_id)
        : offer_list;
      setOffers(filteredOffers);
      if (branch_id) {
        const { branch_name, favicon } = filteredOffers[0]
        setBranchName(branch_name)
        setBranchIcon(favicon)
      }
      setLoading(false);
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  if (offers.length === 0) {
    return <Page404 />;
  }

  return (
    <>
      <SEO metadata={{
        title: branchName,
        favicon: branchIcon
      }} />
      <Box padding={4}>
        <Grid container spacing={4}>
          {offers.map((offer) => {
            return (
              <Grid item key={offer.offer_id} xs={12} sm={6} md={4} lg={3}>
                <OfferBanner offer={offer} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default OffersList;
