import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import DealItemInfo from "./DealItemInfo";

const DealItem = ({
  item: { item_name, item_image, offer_message1, offer_message2 },
  branchLogo,
}) => {
  const { wrapperStyle, imageStyle, titleStyle, logoStyle } = useStyles();
  return (
    <Box position="relative">
      <img src={branchLogo} alt="branch logo" className={logoStyle} />
      <div className={wrapperStyle}>
        <img
          className={imageStyle}
          src={item_image}
          alt={item_name}
          title={item_name}
        />
        <DealItemInfo offer1={offer_message1} offer2={offer_message2} />
      </div>
      <Typography variant="caption" className={titleStyle}>
        {item_name}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperStyle: {
    height: 200,
    overflow: "hidden",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    boxShadow: "0 0 4px rgba(0,0,0,0.2)",
    borderRadius: 10,
    position: "relative",
  },
  imageStyle: {
    width: "auto",
    height: "70%",
    marginTop: theme.spacing(1),
    flexShrink: 0,
  },
  logoStyle: {
    position: "absolute",
    width: theme.spacing(8.5),
    top: -20,
    right: -20,
    borderRadius: 8,
    zIndex: 1,
  },
  titleStyle: {
    color: "black",
  },
}));

DealItem.propTypes = {
  item: PropTypes.object,
};

export default DealItem;
